import { CloseOutlined, PaperClipOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Upload,
} from "antd";
import { Link } from 'gatsby';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { reevaluationService } from "../../../../services/reevaluation";
import { uploadAssetController } from '../../../../../utils/fileUtils';

const ReevaluationFirstStep = ({
  loading,
  handlers,
  setLoading,
  reevaluationData,
  setReevaluationData,
  medicalConsultationId
}) => {
  const [upload, setUpload] = useState(false);
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
  }

  const sendPatientFile = async (
    file,
    documentItem,
    documentIndex,
    prescriptionKey,
    prescriptionIndex,
  ) => {

    if (!handlers.allowedReevaluationDocTypes(file.type)) {
      message.error({
        content: (<p dangerouslySetInnerHTML={{ __html: t('patientReevaluation.allowedTypesMessage')}} />),
        style: {
          marginTop: '40vh',
        },
        duration: 5,
        className: 'file-reevaluations--message-error',
      });

      return;
    }

    try {
      setLoading({
        ...loading,
        sendDocument: true
      })
      if (!beforeUpload) {
        throw new Error();
      }
      const { exam_document_id, name } = documentItem
      const response = await reevaluationService.sendPatientDocument({
        medical_consultation_id: medicalConsultationId,
        medical_prescription_id: reevaluationData.medical_prescription_id,
        exam_id: reevaluationData.extra_data[prescriptionIndex].examId,
        document: {
          new_document: documentItem.new_document,
          prescription_key: prescriptionKey,
          exam_document_id,
          name,
          file_data: {
            file_name: file.name,
            file_size: file.size,
            content_type: file.type
          }
        }
      });

      if (response?.exam_document_id) {
        await uploadAssetController({
          file,
          object_field: 'document',
          object_id: response?.exam_document_id,
          object_type: 'ExamsDocument',
        })

        const _patientDocuments = reevaluationData['extra_data'];
        _patientDocuments[prescriptionIndex].documents[documentIndex] = {
          exam_document_id: response['exam_document_id'],
          name: name,
          file_name: file.name,
          new_document: documentItem.new_document,
        };

        setReevaluationData({
          ...reevaluationData,
          extra_data: _patientDocuments
        });
        setUpload(true);
        message.success({
          content: 'Archivo cargado con éxito.',
          style: {
            marginTop: '30vh'
          }
        })
      } else {
        message.error({
          content: 'Ha ocurrido un error al cargar el archivo. Inténtalo nuevamente',
          style: {
            marginTop: '35vh'
          }
        })
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al cargar el archivo. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      });
    } finally {
      setLoading({
        ...loading,
        sendDocument: false
      });
    }
  }

  const downloadPatientFile = (exam_document_id, file_name) => {
    setLoading(loading => { return { ...loading, downloadPatientFile: true } })
    reevaluationService
      .getAsset({ id: exam_document_id, type: 'ExamsDocument', field: 'document' })
      .then(response => {
        handlers.urlDownloadAndOpen(response.document_url, file_name);
        message.success({
          content: 'Prescripción descargada exitosamente',
          style: {
            marginTop: '40vh'
          }
        })
      }).catch(err => {
        console.error('Error interno:', err)
        message.error({
          content: `Error interno: ${err}. Inténtalo nuevamente`,
          style: {
            marginTop: '40vh'
          }
        })
      }).finally(() => {
        setLoading(loading => { return { ...loading, downloadPatientFile: false } })
      })
  }

  const handleRemoveDocument = async (
    exam_document_id,
    documentName,
    prescriptionKey,
    prescriptionIndex,
    new_document,
    index
  ) => {
    try {
      setLoading(loading => { return { ...loading, deleteDocument: true } });
      const response = await deleteDocument(
        exam_document_id,
        documentName,
        prescriptionKey,
        new_document,
      );

      const extra_data = reevaluationData.extra_data;

      extra_data.forEach((prescription) => {
        if (prescription.key === prescriptionKey) {
          let documents = prescription.documents;

          if (new_document) {
            documents.splice(index, 1);
          } else {
            documents[index] = {
              ...documents[index],
              exam_document_id: null,
              file_name: null,
            };
          }

          let newDocumentsCounter = 0;

          documents = [
            ...documents.filter((document) => !document.new_document || document.exam_document_id),
            ...documents.filter((document) => document.new_document && !document.exam_document_id),
          ];

          documents.forEach((document) => {
            if (document.new_document) {
              if (document.exam_document_id) {
                response?.documents?.forEach((responseDocument) => {
                  if (document.exam_document_id === responseDocument.exam_document_id) {
                    document.name = responseDocument.name;
                    newDocumentsCounter += 1;
                  }
                });
              } else {
                document.name = `Nuevo documento ${newDocumentsCounter + 1}`;
                newDocumentsCounter += 1;
              }
            }
          });
          prescription.documents = documents;
        }
      });


      setReevaluationData({
        ...reevaluationData,
        extra_data,
      });
    } catch (error) {
      console.error(error);
      message.error({
        content: 'Ha ocurrido un error al eliminar el archivo. Inténtalo nuevamente',
        style: {
          marginTop: '35vh'
        }
      });
    } finally {
      setLoading(loading => { return { ...loading, deleteDocument: false } });
    }
  }

  const deleteDocument = async (
    exam_document_id,
    documentName,
    prescriptionKey,
    new_document,
  ) => {
    const response = await reevaluationService.deletePatientDocument({
      exam_document_id: exam_document_id,
      prescription_key: prescriptionKey,
      name: documentName,
      new_document: new_document,
      medical_consultation_id: medicalConsultationId,
      medical_prescription_id: reevaluationData['medical_prescription_id']
    })
    if (response.state === 'success') {
      message.success({
        content: "Archivo eliminado.",
        style: {
          marginTop: "35vh",
          fontSize: 16,
        },
      });
    } else {
      throw new Error('Ha ocurrido un error al eliminar el archivo. Inténtalo nuevamente');
    }
    return response;
  }

  const addNewDocument = (itemKey) => {
    const _patientDocuments = reevaluationData['extra_data'];
    let documentsQuantity = 0

    _patientDocuments.forEach(item => {
      if (item.key === itemKey) {
        item.documents.forEach(elem => {
          if (elem?.new_document) {
            documentsQuantity += 1
          }
        })
      }
    })

    _patientDocuments.forEach(item => {
      if (item.key === itemKey) {
        item.documents.push({
          exam_document_id: null,
          name: `Nuevo documento ${documentsQuantity + 1}`,
          new_document: true
        })
      }
    })

    setReevaluationData({
      ...reevaluationData,
      extra_data: _patientDocuments
    });
  }

  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPngOrPdf = file.type === 'image/jpeg'
        || file.type === 'image/png'
        || file.type === 'application/pdf';

      if (!isJpgOrPngOrPdf) {
        message.error('Solo puedes subir archivos JPG/PNG/PDF.');
      }

      const isLow6MB = file.size / 1024 / 1024 < 6;
      if (!isLow6MB) {
        message.error({
          content: 'El documento que intenta subir pesa más de 6MB.',
          style: {
            marginTop: '40vh'
          }
        })
      }
      return isLow6MB && isJpgOrPngOrPdf;
    }
  }

  const onFinish = () => {
    try {
      setLoading(loading => { return { ...loading, sendFakeDocument: true } });
      if (!upload) {
        throw new Error();
      }
      let isDocumentAdded = false

      const _patientDocuments = reevaluationData.extra_data;

      _patientDocuments?.forEach(item => {
        item.documents.forEach(elem => {
          if (elem['file_name']) {
            isDocumentAdded = true
          }
        })
      })

      if (isDocumentAdded) {
        setUpload(false);
        message.success({
          content: 'Documentos enviados exitosamente',
          style: {
            marginTop: '40vh'
          }
        })
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      message.info({
        content: 'Debes cargar al menos 1 documento para enviar',
        style: {
          marginTop: '40vh'
        }
      })
    } finally {
      setLoading(loading => { return { ...loading, sendFakeDocument: false } });
    }
  }

  const hasDocuments = () => {
    return reevaluationData?.extra_data?.filter(document => document?.documents?.length > 0)?.length > 0
  };

  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="header-wrapper pt-20 pb-20">
        <h2 className="text-center mb-5">{t('patientReevaluation.stepsInfo.stepOneTitle')}</h2>
        {
          hasDocuments() ?
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.documentText')}</p>
            :
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.branchOfficeText')}</p>
        }
      </Col>
      <Col lg={24} xs={24} className="body-wrapper mt-40">
        {
          hasDocuments() ?
            <Row justify="center">
              <Col xxl={9} xl={11} lg={10} xs={22}>
                <Row justify="center" gutter={[0, 15]}>
                  <Col lg={24} xs={24} className="mt-5">
                    <p className="text-instruction text-center mt-20"><strong>{t('patientReevaluation.stepsInfo.uploadDocumentsText')}</strong></p>
                    <h4 className="carousel-title mt-10 mb-30 text-center">
                      <b>
                        El tamaño de los archivos no debe exceder los 6MB.
                      </b>
                    </h4>
                    <Row justify="center" gutter={[0, 10]}>
                      <Col lg={24} xs={24}>
                        <Form
                          form={form}
                          onFinish={onFinish}
                          size="large"
                        >
                          {
                            reevaluationData?.extra_data?.map((item, index) => {
                              return (
                                <Row justify="center mb-20" key={item.key} gutter={[0, 15]}>
                                  <Col lg={24} xs={24}>
                                    <p className="bold mb-0">{item.name}</p>
                                  </Col>
                                  {
                                    item?.documents?.map((elem, inx) => {
                                      return (
                                        < Col lg={24} xs={24} className="gray-item pl-40 pr-40 pt-20 pb-20" key={inx} >
                                          <Row justify="center">
                                            <Col lg={19} xs={24}>
                                              {
                                                elem.new_document &&
                                                <Row justify="end">
                                                  <Col>
                                                    <Button
                                                      onClick={
                                                        () => handleRemoveDocument(
                                                          elem.exam_document_id,
                                                          elem.name,
                                                          item.key,
                                                          index,
                                                          elem.new_document,
                                                          inx,
                                                        )
                                                      }
                                                      disabled={loading.deleteDocument || loading.sendDocument}
                                                      className="cmt-button secondary non-outline floating p-0"
                                                      type="primary"
                                                      style={{ boxShadow: 'none' }}
                                                    >
                                                      <CloseOutlined style={{ fontSize: 20, color: '#223a70' }} />
                                                    </Button>
                                                  </Col>
                                                </Row>
                                              }
                                              <Row>
                                                <p className="mb-10">{elem.name}</p>
                                              </Row>
                                              <Row gutter={15} align="middle">
                                                <Col lg={22} xs={22}>
                                                  {
                                                    elem.file_name ?
                                                      <Button type="text" loading={loading.downloadPatientFile} onClick={
                                                        () => downloadPatientFile(
                                                          elem.exam_document_id,
                                                          elem.file_name
                                                        )
                                                      }
                                                        className="file-text mb-0" style={{ "cursor": "pointer" }}>
                                                        {elem.file_name}
                                                      </Button>
                                                      :
                                                      <Form.Item
                                                        name={elem.name}
                                                        className="mb-0"
                                                      >
                                                        <Upload
                                                          name="logo"
                                                          listType="picture"
                                                          beforeUpload={(file) => {
                                                            sendPatientFile(file, elem, inx, item.key, index);
                                                            return false;
                                                          }}
                                                        >
                                                          <Button
                                                            loading={loading.sendDocument}
                                                            className="cmt-button secondary outline"
                                                            type="primary"
                                                            block
                                                          >
                                                            <PaperClipOutlined className="mr-lg-10" style={{ color: '#223a70', fontSize: 20 }} />
                                                            Cargar documento aquí
                                                          </Button>
                                                        </Upload>
                                                      </Form.Item>
                                                  }
                                                </Col>
                                                <Col lg={2} xs={2}>
                                                  <Button
                                                    loading={loading.deleteDocument}
                                                    onClick={
                                                      () => handleRemoveDocument(
                                                        elem.exam_document_id,
                                                        elem.name,
                                                        item.key,
                                                        index,
                                                        elem.new_document,
                                                        inx,
                                                      )
                                                    }
                                                    disabled={!elem.file_name}
                                                    className="cmt-button secondary non-outline p-0"
                                                    type="primary"
                                                    style={{ boxShadow: 'none' }}
                                                  >
                                                    {
                                                      !loading.deleteDocument &&
                                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 148.61 189.36"><g id="Capa_2" dataname="Capa 2"><g id="Capa_1-2" dataname="Capa 1"><path d="M47.16,21.25c0-3.94-.14-7.24,0-10.53C47.49,4.64,51.6.29,57.66.17q16.5-.33,33,0c6.37.11,10.48,4.42,10.78,10.79.15,3.28,0,6.58,0,10.4,11.53,0,22.48-.06,33.43,0,7.49,0,12.84,3.93,13.53,10a92.45,92.45,0,0,1,.07,10.16H.13c0-3.65-.34-7.14.08-10.54.69-5.58,5.89-9.6,12.18-9.65C23.71,21.19,35,21.25,47.16,21.25Zm46.89-.2c0-3.38,0-6.19,0-9C94,9,92.36,7.48,89.32,7.48c-10,0-20-.16-29.93.17-1.52,0-3.93,2.14-4.25,3.64-.67,3.1-.2,6.43-.2,9.76Z" /><path d="M15.42,54.5v4.63q0,58.15,0,116.31c0,10.25,4.24,13.91,16.09,13.91q42.55,0,85.09,0c12.36,0,16.25-3.36,16.25-14.11q0-57.93,0-115.88V54.5Z" /></g></g></svg>
                                                    }
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <p className="small mt-5 mb-0">{t('patientReevaluation.stepsInfo.fileTypesText')}</p>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                      )
                                    })
                                  }
                                  <Col>
                                    <Button
                                      onClick={() => addNewDocument(item.key)}
                                      className="cmt-button secondary"
                                      type="primary"
                                      size="middle"
                                    >
                                      <i className="icon-system-plus mr-5" />
                                      Agregar otro documento
                                    </Button>
                                  </Col>
                                </Row>
                              )
                            })
                          }
                          <Row justify="center" className="mt-70" gutter={[0, 10]}>
                            <Col lg={24} xs={24}>
                              <p className="text-instruction text-center">{t('patientReevaluation.stepsInfo.solutionText')}</p>
                            </Col>
                            <Col xxl={10} xl={11} lg={13} xs={24}>
                              <Button
                                loading={loading.sendFakeDocument}
                                htmlType="submit"
                                className="cmt-button primary"
                                type="primary"
                              >
                                Enviar documentos
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            <Row justify="center">
              <Col xxl={10} xl={12} lg={10} xs={22} className="branch-office-wrapper">
                <p className="bold text-center">{t('patientReevaluation.stepsInfo.presenceInfoText1')} {formatDate(reevaluationData['final_date'])} {t('patientReevaluation.stepsInfo.presenceInfoText2')}</p>
                <p className="text-center mb-30">{t('patientReevaluation.stepsInfo.reminderInfo1')} <strong>{reevaluationData['reservation_number']}</strong> y <strong>{t('patientReevaluation.stepsInfo.reminderInfo2')}</strong></p>
                <Row justify="center" align="middle" className="mb-10">
                  <Col>
                    <i className="icon-system-placeholder mr-5" />
                  </Col>
                  <Col>
                    <p className="bold primary mt-5 mb-0">{t('patientReevaluation.stepsInfo.yourBranchOfficeText')} {reevaluationData['branch_office_name']}</p>
                  </Col>
                </Row>
                <p className="text-center primary small">{t('patientReevaluation.stepsInfo.openingHoursText')}</p>
                <Row justify="center">
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5"><strong>{reevaluationData['branch_office_name']}</strong></p>
                  </Col>
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5">{reevaluationData['branch_office_address']}</p>
                  </Col>
                  <Col lg={24} xs={24}>
                    <p className="text-center small mb-5">{reevaluationData['branch_office_phone']}</p>
                  </Col>
                  <Col lg={24} xs={24} className="mt-30">
                    <Link
                      to={`/${t('navbar.branchOffices').toLocaleLowerCase()}/`}
                    >
                      <Button
                        className="cmt-button primary non-outline"
                        type="primary"
                        style={{ boxShadow: 'none', fontSize: 14 }}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <i className="icon-system-plus mr-5" />
                        {t('navbar.branchOffices').toUpperCase()}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
        }
      </Col>
    </Row>
  )
}

export default ReevaluationFirstStep;