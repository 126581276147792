import { sortBy, uniq } from 'lodash';
import moment from 'moment';
import PDFMerger from 'pdf-merger-js';
import { convertBase64ToBlob } from '../../../../../utils/fileUtils';
import {
  downloadReport
} from '../../../../services/reservations';

const useServices = (exams, reservation) => {
  const evalautionType = [
    "Ocupacional",
    "EMPO",
    "EMOA",
    "EMRU",
    "EMRE",
    "EMOR",
  ];

  const downloadable = (blob, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadOrderPeruExams = (response) => {
    let orderedPrestations = [];
    const annex16 = response?.filter(item => item.code === 'ANEXO16');
    const annex16a = response?.filter(item => item.code === 'ALTURA16A');
    const health = sortBy(
      response?.filter(item => item.prestation_department_id === 1 && !['ANEXO16', 'ALTURA16A'].includes(item.code)),
      (item) => item.type
    );
    const psycology = sortBy(
      response?.filter(item => item.prestation_department_id === 2),
      (item) => item.type
    );
    if (annex16?.length) {
      orderedPrestations = orderedPrestations.concat(annex16);
    }
    if (annex16a?.length) {
      orderedPrestations = orderedPrestations.concat(annex16a);
    }
    if (annex16?.length || annex16a?.length) {
      orderedPrestations = orderedPrestations.concat([...psycology, ...health]);
    } else if (response?.length) {
      orderedPrestations = orderedPrestations.concat([...health, ...psycology]);
    }
    return orderedPrestations;
  };

  const getAttachment = (services, response, patientItem) =>
    services.forEach(filteredPrestation => {
      if (filteredPrestation.attached_services?.length) {
        const attachedServices = response.filter(prestation =>
          filteredPrestation.attached_services?.some(attached =>
            attached.attached_id === prestation.service_id
            && attached.attached_type === prestation.type
            && patientItem.patient_id === prestation.patient_id
          ));

        attachedServices?.map(attached => {
          attached.exams?.map(exam => {
            if (filteredPrestation.exams) {
              if (!filteredPrestation.exams.some(_exam => _exam.trx === exam.trx)) {
                filteredPrestation.exams.push(exam);
              }
            } else {
              filteredPrestation.exams = [exam];
            }
          });

          attached.medical_reports?.map(report => {
            if (filteredPrestation.medical_reports && report?.name.includes('Recortes Anexo16.pdf')) {
              if (!filteredPrestation.medical_reports?.some(_report => _report.id === report.id)) {
                filteredPrestation.medical_reports.push(report);
              }
            } else if (report?.name.includes('Recortes Anexo16.pdf')) {
              filteredPrestation.medical_reports = [report];
            }
          });

        });
      }
    });

  const orderPeruExams = (response) => {
    let orderedPrestations = [];
    if (response?.length) {
      const annex = ['ANEXO16', 'ALTURA16A'];

      response?.map(patientItem => {
        const health = response.filter(prestation => prestation.prestation_department_id === 1
          && !annex.includes(prestation.code) && prestation.patient_id === patientItem.patient_id
          && prestation.attached_services?.some(attached =>
            response.some(itemAttached =>
              itemAttached.service_id === attached.attached_id
              && itemAttached.type === attached.attached_type
              && itemAttached.patient_id === prestation.patient_id
            )
          )
        );

        const health16 = response.filter(prestation => prestation.prestation_department_id === 1
          && annex.includes(prestation.code) && prestation.patient_id === patientItem.patient_id
          && prestation.attached_services?.some(attached =>
            response.some(itemAttached =>
              itemAttached.service_id === attached.attached_id
              && itemAttached.type === attached.attached_type
              && itemAttached.patient_id === prestation.patient_id
            )
          )
        );

        const other = response.filter(prestation => prestation.prestation_department_id !== 1
          && !annex.includes(prestation.code) && prestation.patient_id === patientItem.patient_id
          && prestation.attached_services?.some(attached =>
            response.some(itemAttached =>
              itemAttached.service_id === attached.attached_id
              && itemAttached.type === attached.attached_type
              && itemAttached.patient_id === prestation.patient_id
            )
          )
        );

        const itemOnList = [...health, ...health16, ...other].some(_item =>
          _item.id === patientItem.id
          || _item.attached_services?.some(service => service.attached_id === patientItem.service_id && _item.patient_id === patientItem.patient_id)
        );
        const itemOnOrderedList = orderedPrestations.some(_item =>
          _item.id === patientItem.id
          || _item.attached_services?.some(service => service.attached_id === patientItem.service_id && _item.patient_id === patientItem.patient_id)
        );
        if (itemOnList && !itemOnOrderedList) {
          if (health?.length) {
            getAttachment(health, response, patientItem);
            orderedPrestations = [
              ...orderedPrestations,
              ...health,
            ];
          } else if (!health?.length && health16?.length) {
            getAttachment(health16, response, patientItem);
            orderedPrestations = [
              ...orderedPrestations,
              ...health16,
            ];
          } else if (!health?.length && !health16?.length && other?.length) {
            getAttachment(other, response, patientItem);
            orderedPrestations = [
              ...orderedPrestations,
              ...other,
            ];
          }
        } else if (!itemOnList && !itemOnOrderedList) {
          orderedPrestations.push(patientItem);
        }
      });
    }
    return (orderedPrestations?.length && sortBy(orderedPrestations, (prestation) => (prestation.patient_id && prestation.type))) || response;;
  };

  const downloadMedicalReportMerged = async (downloadExam) => {
    const merger = new PDFMerger();
    const patientExams = exams?.filter(exam =>
      downloadExam.attached_services.some((service =>
        service.attached_id === exam.service_id
        && service.attached_type === exam.type
        && downloadExam.patient_id === exam.patient_id
      )) || exam.id === downloadExam.id
    );
    let pdfName = '';
    for await (const exam of downloadOrderPeruExams(patientExams)) {
      if (downloadExam.signed) {
        const date = moment(exam.admission_date).format('DD-MM-YYYY');
        pdfName = `${exam.document_number} - ${exam.last_name} ${exam.names} - ${exam.company_name} - ${evalautionType[exam.evaluation_type]} - CMT - ${date}.pdf`;
        const pdf = await downloadReport({
          examId: exam.id,
          number: reservation.number,
        });
        const base64 = `data:application/pdf;base64,${pdf}`;
        await merger.add(convertBase64ToBlob(base64));
      }
    }
    await merger.setMetadata({
      producer: "pdf-merger-js based script"
    });

    const mergedPdf = await merger.saveAsBlob();
    downloadable(mergedPdf, pdfName)
  }

  return {
    downloadOrderPeruExams,
    orderPeruExams,
    downloadMedicalReportMerged,
    downloadable,
  };
};

export default useServices;