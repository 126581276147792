import { usePermify } from "@permify/react-role";
import {
  Button,
  Checkbox,
  Popover,
  message,
} from "antd";
import React, { useState } from 'react';
import config from "../../../../config";
import {
  downloadReport,
  reservationService
} from '../../../../services/reservations';

const DownloadAttachment = ({
  service,
  selectedExams,
  handleSelectExam,
  reservation,
  countryCode,
  downloadMedicalReportMerged,
  settings,
}) => {
  const { isAuthorized, isLoading } = usePermify();
  const [loading, setLoading] = useState(false);
  const filterReport = service?.medical_reports?.find(medical_report => medical_report?.name?.toUpperCase().includes('RRHH'));

  const downloadMedicalReport = async (service) => {
    setLoading(true);
    try {
      if (settings.mergeDownloadReport[countryCode] && service.attached_services) {
        await downloadMedicalReportMerged(service);
      } else {
        const pdf = await downloadReport({
          examId: service.id,
          number: reservation.number,
        });
        const base64 = `data:application/pdf;base64,${pdf}`;

        const downloadLink = document.createElement("a");
        const fileName = `informe-${service.id}.pdf`;
        downloadLink.href = base64;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: "Error en descarga del reporte",
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadReport = async (service) => {
    const actions = {
      PE: async () => {
        const doctorPermission = await isAuthorized([], "medical_administration");
        if (doctorPermission) {
          downloadMedicalReport(service);
        } else {
          downloadFile(filterReport);
        }
      },
      CL: () => {
        downloadMedicalReport(id);
      },
      default: () => {
        console.warn("Country not supported for report download:", countryCode);
      },
    };

    const action = actions[countryCode] || actions.default;
    await action();
  }

  const downloadFile = async (exam) => {
    setLoading(true);
    try {
      const id = exam.id || exam.trx;
      const type = exam.trx ? 'AdmissionProcedureResult' : 'MedicalReport';
      const response = await reservationService
        .downloadExams({
          id,
          field: 'document',
          type,
        });

      if (response.document_type.includes('application/dicom')) {
        const newWindow = window.open(`${config.dicomURL}?url=${response.document_url}`, '_blank', 'noopener,noreferrer')
        if (newWindow) {
          newWindow.opener = null;
        }
      } else {
        window.open(response.document_url, '_blank');
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: "Error en descarga del reporte",
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  if (service?.medical_reports?.length === 1 && filterReport) {
    return (
      <Button
        loading={loading}
        className="download-report-button"
        onClick={() => downloadFile(filterReport)}
      >
        Descargar informe
      </Button>
    );
  } else {
    return (
      <>
        {
          (service?.exams?.length || service?.medical_reports?.length)
            ? (
              <>
                <Button
                  loading={loading}
                  className="download-report-button"
                  onClick={() => {
                    handleDownloadReport(service);
                  }}
                >
                  Descargar informe
                </Button>
                <Popover
                  trigger="click"
                  content={
                    <div>
                      {
                        [
                          ...(service.exams || []),
                          ...(service.medical_reports || []),
                        ].map((item) =>
                          <Button
                            key={`${item.name} - ${(item.id || item.trx)}`}
                            loading={loading}
                            type="link"
                            onClick={() => downloadFile(item)}
                            className="pl-0"
                          >
                            <span className="icon-system-download mr-10" />
                            {item.procedure || item.name}
                          </Button>
                        )}
                    </div>
                  }
                  title="Adicionales"
                  overlayClassName="download-additional-popover"
                >
                  <span className="icon-clip" />
                </Popover>
                {
                  settings.showDownloadAll[countryCode] &&
                  <Checkbox
                    className="desktop-checkbox ml-15"
                    checked={
                      selectedExams?.includes(service.consultationId)
                    }
                    onClick={(event) =>
                      handleSelectExam(
                        event.target.checked,
                        service.consultationId
                      )
                    }
                  />
                }
              </>
            ) : (
              <div className="no-clip-wrapper">
                {
                  (
                    (settings.showDownloadNoClip[countryCode] === 'anyMedicalReport')
                    || (
                      settings.showDownloadNoClip[countryCode] === 'noMedicalReport'
                      && (!service?.medical_reports || service?.medical_reports?.length === 0)
                    )
                  )
                    ? <React.Fragment>
                      <Button
                        loading={loading}
                        className="download-report-button"
                        onClick={() => downloadMedicalReport(service)}
                      >
                        Descargar informe
                      </Button>
                      {
                        settings.showDownloadAll[countryCode] &&
                        <Checkbox
                          className="desktop-checkbox ml-15"
                          checked={
                            selectedExams?.includes(service.consultationId)
                          }
                          onClick={(event) =>
                            handleSelectExam(
                              event.target.checked,
                              service.consultationId
                            )
                          }
                        />
                      }
                    </React.Fragment>
                    : <span style={{ display: "inline-block" }} className="ml-20" />
                }
              </div>
            )}
      </>
    );
  }
};

export default DownloadAttachment;