import React from 'react'
import {
  Row,
  Col
} from "antd"
import { ExclamationCircleFilled } from "@ant-design/icons"
import { useTranslation } from "react-i18next"


const ReevaluationThirdStep = ({ isFinish }) => {
  const { t } = useTranslation()

  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="header-wrapper pt-20 pb-20 mt-60">
        <h2 className="text-center mb-5">{t('patientReevaluation.stepsInfo.stepThreeTitle')}</h2>
        <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.confirmationText')}</p>
      </Col>
      <Col lg={24} xs={24} className="body-wrapper mt-50 mb-40">
        {
          isFinish ?
            <Row justify="center" className="confirmation-container">
              <Col>
                <i className="check-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72">
                    <g id="Grupo_59" dataname="Grupo 59" transform="translate(-585.884 -864.53)">
                      <rect id="Rectángulo_81" dataname="Rectángulo 81" width="72" height="72" rx="36" transform="translate(585.884 864.53)" fill="#2f9980" />
                      <path id="Trazado_76" dataname="Trazado 76" d="M658.9,914.463l-3.393,3.4.028.029-19.834,19.849L623.629,925.65l3.654-3.657,8.423,8.429,16.179-16.191.027.026,3.394-3.395Z" transform="translate(-19.383 -23.769)" fill="#fff" />
                    </g>
                  </svg>
                </i>
              </Col>
              <Col lg={24} xs={23}>
                <h3 className="success text-center mt-20">{t('patientReevaluation.stepsInfo.finishInfoTitle')}</h3>
              </Col>
              <Col lg={8} xs={22}>
                <p className="text-center mt-15">{t('patientReevaluation.stepsInfo.finishInfoText')}</p>
              </Col>
            </Row>
            :
            <Row justify="center" className="confirmation-container">
              <Col className='mb-0'>
                <ExclamationCircleFilled style={{ fontSize: 40, color: '#223a70' }} />
              </Col>
              <Col lg={24} xs={23}>
                <h3 className="progress text-center mt-30">{t('patientReevaluation.stepsInfo.inProgressTitle')}</h3>
              </Col>
              <Col lg={8} xs={22}>
                <p className="text-center mt-40">{t('patientReevaluation.stepsInfo.inProgressText')}</p>
              </Col>
            </Row>
        }
      </Col>
    </Row>
  )
}

export default ReevaluationThirdStep