import React, { useState, useEffect } from 'react';
import { DocumentCard } from 'cmt-ui-kit';
import Error500 from '../../../../images/Error500.svg';
import { Row, Col, Button, InputNumber, Modal, Form, Input, Radio, Select, message, Spin } from 'antd';
import { scheduleTimeService } from '../../../../services/scheduleTime';
import { sendReservationContact } from '../../../../services/sendForm';
import { useTranslation } from "react-i18next";
import { PhoneInput } from 'cmt-ui-kit';
import debounce from "lodash/debounce";

const { Option } = Select;

const SelectBranchOffice = ({
  setScheduleTimeState,
  setBranchOffice,
  branchOffice,
  documentNumber,
  setDocumentNumber,
  costCenter,
  setCostCenter,
  documentType,
  setDocumentType,
  countryCode,
  cartState,
  setCart,
  queryParams,
}) => {
  const [branchOffices, setBranchOffices] = useState();
  const [loading, setLoading] = useState({
    general: false,
    submit: false,
  });

  const [formModalVisible, setFormModalVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [canReserve, setCanReserve] = useState();
  const [costCenters, setCostCenters] = useState({ exists: false, list: [] });
  const [patientsQuantity, setPatientsQuantity] = useState(1);
  const [form] = Form.useForm();
  const [formNormal] = Form.useForm();
  const { t } = useTranslation();


  const fetchBranchOffices = async () => {
    setLoading(loading => ({ ...loading, general: true }));
    try {
      const response = await scheduleTimeService.branchOffices({ countryCode });
      setBranchOffices(response);
    } catch (error) {
      console.error('Error', error);
      message.error(`Error al buscar las ${t('scheduleTime.selectBranchOffice.branch_office')}`)
    } finally {
      setLoading(loading => ({ ...loading, general: false }));
    }
  };

  useEffect(() => {
    fetchBranchOffices();

    if (documentNumber && documentNumber !== undefined) {
      checkDocumentNumber(documentNumber);
    }

    if (cartState.total > 0) {
      setPatientsQuantity(cartState.total);
    }
  }, [documentNumber]);


  useEffect(() => {
    formNormal.setFieldsValue({ documentType, documentNumber });
  }, []);

  useEffect(() => {
    if (queryParams && queryParams.branchOfficeId && branchOffices && branchOffices.length) {
      onChangeBranchOffice(queryParams.branchOfficeId);
      setPatientsQuantity(queryParams.patientsQty);
    }
  }, [queryParams, branchOffices]);


  const handleChangeNumber = value => {
    if (value <= 10) {
      setPatientsQuantity(value);
    }
  };

  const handleChangeState = () => {
    if (cartState.total !== patientsQuantity) {
      setCart({
        total: patientsQuantity,
        total_amount: 0,
        patients: [...Array(patientsQuantity).keys()].map(number => ({
          number,
          documentType: null,
          documentNumber: null,
          phone: null,
          services: [],
          block: null,
          date: null,
          amount: 0,
          email: null,
          profession: null,
          evaluation_type: null,
        }))
      });
    }

    if (canReserve) {
      formNormal.validateFields()
        .then(values => {
          if (!values.errorFields) {
            setScheduleTimeState(1);
          }
        })
        .catch(error => {
          console.error(error)
          message.error({
            content: 'Documento incorrecto. Revisa los dígitos.',
            style: {
              marginTop: '40vh',
            }
          });
        })
    }

    if (window.hj) {
      window.hj('event', 'reservations-step2')
    }
  };

  const onChangeBranchOffice = (value) => {
    const findBranchOffice = branchOffices.find(branchOffice => branchOffice.id === value);
    setBranchOffice(findBranchOffice);
    if (cartState && cartState.patients && cartState.patients.find(patient => patient.services && patient.services.length > 0)) {
      setCart(cart => ({ ...cart, patients: cart.patients.map(patient => ({ ...patient, services: [] })) }))
    }
  };

  const showFormModal = () => {
    setFormModalVisible(true);
  };

  const onChangeCostCenter = (value) => {
    setCostCenter(value);
  };

  const checkDocumentNumber = async (number) => {
    setLoading(loading => ({ ...loading, general: true }));
    try {
      if (number?.length > 4) {
        const [response, newCostCenters] = await Promise.all([
          scheduleTimeService.canReserve({ document_number: number, document_type: documentType }),
          scheduleTimeService.costCenters({ document_number: number, document_type: documentType })
        ]);
        setCanReserve(response.canReserve);
        setCostCenters({
          exists: newCostCenters?.length,
          list: newCostCenters
        });
      }
    } catch (error) {
      console.error(error);
      message.error(`Error al validar numero de documento, intente nuevamente`)
      setCanReserve(false)
      setCostCenters({ exists: false, list: [] });
    } finally {
      setLoading(loading => ({ ...loading, general: false }));
    }
  };

  const onChangeDocumentNumber = async (e) => {
    const documentValue = e.target.value;

    if (documentValue.length > 0) {
      setDocumentNumber(e.target.value);
      return;
    }

    setDocumentNumber();
    if (window.hj) {
      window.hj('event', 'reservations-step1')
    }
  };

  const onFinish = async (values) => {
    setLoading(loading => ({ ...loading, submit: true }));
    try {
      await sendReservationContact({
        'nameAndLastName': values.nameAndLastName,
        'phone': values.phone,
        'email': values.email,
      });
      setIsSuccess(true);
    } catch (err) {
      message.error('Error en el envío del formulario de atención')
      console.error(err)
    } finally {
      setLoading(loading => ({ ...loading, submit: false }));
    }
  };

  const closeModal = () => {
    setFormModalVisible(false);
  };

  const fetchCostCenter = debounce(async (name) => {
    setLoading(loading => ({ ...loading, general: true }));
    try {
      const response = await scheduleTimeService.costCenters({
        document_number: documentNumber,
        document_type: documentType,
        name,
      });
      setCostCenters((_costCenter) => ({
        ..._costCenter,
        list: response,
      }));
    } catch (error) {
      message.error(`Error al buscar centro de costos`)
      console.error(error);
    } finally {
      setLoading(loading => ({ ...loading, general: false }));
    }
  }, 500);

  return (
    <Spin className="cmt-spin" spinning={loading.general}>
      <Row justify="center" className="select-branch-office-container">
        <Col lg={22} md={23} sm={23} xs={23}>
          <Row justify="center" className="mt-40">
            <Col lg={22} md={24}>
              <h2 className="text-center">
                {t('scheduleTime.selectBranchOffice.selectBranchOfficeTitle')}
              </h2>
            </Col>
            <Col xl={17} lg={24} className="mt-20 branch-office-large-wrapper">
              <Row justify="center">
                <Col lg={24}>
                  {
                    branchOffices &&
                    <Radio.Group
                      onChange={e => onChangeBranchOffice(e.target.value)}
                      size="large"
                      style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}
                      defaultValue={queryParams ? queryParams.branchOfficeId : (branchOffice && branchOffice.id)}
                    >
                      {
                        branchOffices.map(data =>
                          <Radio.Button
                            value={data.id}
                            key={data.id}
                            size="large"
                            className="mr-15 mb-10 text-center"
                            style={{ height: '100%', width: '23%' }}
                          >
                            {data.name}&nbsp;
                            <span className="branch-office-city">
                              {data.city === "Santiago" && data.city}
                            </span>
                            <br />
                            {
                              (branchOffice.address && branchOffice.id === data.id) &&
                              <span className='branch-office-address'>{data.address}</span>
                            }
                          </Radio.Button>
                        )
                      }
                    </Radio.Group>
                  }
                </Col>
              </Row>
            </Col>
            <Col md={24} xs={24} className="mt-20 branch-office-medium-wrapper">
              <Row justify="center">
                <Col md={23} xs={23}>
                  <Select
                    size="large"
                    value={branchOffice.id}
                    placeholder="Seleccionar sucursal"
                    style={{ width: '100%' }}
                    onChange={onChangeBranchOffice}
                  >
                    {
                      branchOffices && branchOffices.map((data, index) =>
                        <Option key={index} value={data.id}>
                          {data.name}
                        </Option>
                      )
                    }
                  </Select>
                </Col>
                {
                  branchOffice.address &&
                  <Col md={23} xs={23} className="mt-15">
                    <p className="branch-office-address text-center mb-0">
                      {branchOffice.address}
                    </p>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
          <Row justify="center" className="mt-40 input-patients-container">
            <Col lg={20} md={23} sm={23} xs={23}>
              <h2 className="text-center">
                {t('scheduleTime.selectBranchOffice.selectCompanyTitle')}
              </h2>
            </Col>
            <Col xl={14} lg={16} md={24} xs={24}>
              <Row justify="center">
                <Col md={12} xs={23} className="mt-10">
                  <Form
                    form={formNormal}
                    layout="vertical"
                  >
                    <DocumentCard
                      countryCode={countryCode}
                      setDocumentType={setDocumentType}
                      form={formNormal}
                      handleOnDocumentNumberBlur={onChangeDocumentNumber}
                      type='company'
                    />
                  </Form>
                  {
                    canReserve === false &&
                    <span className="message-error">
                      {t('scheduleTime.selectBranchOffice.errorCompanyText')}
                    </span>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          {
            (costCenters?.exists)
              ? (
                <Row justify="center" className="mt-40 input-patients-container">
                  <Col lg={20} md={23} sm={23} xs={23}>
                    <h2 className="text-center">{t('scheduleTime.selectBranchOffice.selectCostCenterTitle')}</h2>
                  </Col>
                  <Col xl={14} lg={16} md={24} xs={24}>
                    <Row justify="center">
                      <Col md={12} xs={23} className="mt-10">
                        <Select
                          placeholder="Selecciona un centro de costo"
                          style={{ width: '100%' }}
                          size="large"
                          onChange={onChangeCostCenter}
                          className='cmt-select'
                          defaultValue={costCenter && costCenter}
                          filterOption={false}
                          showArrow
                          showSearch
                          onSearch={fetchCostCenter}
                          allowClear
                        >
                          {
                            costCenters?.list?.map((costCenter, index) =>
                              <Option key={index} value={costCenter.id}>
                                {costCenter.name}
                              </Option>
                            )
                          }
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )
              : null
          }
          <Row justify="center" className="mt-40 input-patients-container">
            <Col lg={20} md={23} sm={23} xs={23}>
              <h2 className="text-center">{t('scheduleTime.selectBranchOffice.selectPatientQuantityTitle')}</h2>
            </Col>
            <Col xl={11} lg={16} md={24} xs={24}>
              <Row justify="center" className="mt-20 patients-quantity-wrapper">
                <Col xl={13} lg={11} md={24} xs={24}>
                  <p className="mb-0 mt-5 text-center">{t("scheduleTime.selectBranchOffice.quantityPatients")}</p>
                </Col>
                <Col className="input-wrapper" xl={5} lg={5} md={8} xs={15} style={{ display: 'flex', justifyContent: 'center' }}>
                  <InputNumber
                    className="cmt-input-number"
                    min={1}
                    max={countryCode === 'CL' ? 10 : 5}
                    value={patientsQuantity}
                    onStep={handleChangeNumber}
                    onChange={handleChangeNumber}
                    size="large"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            justify="center"
            className={`animate__animated alert-wrapper mt-30 mb-30
              ${patientsQuantity === (countryCode === 'CL' ? 10 : 5) && 'animate__headShake'}`
            }
          >
            <Col lg={13} md={15} sm={23} xs={23}>
              <Row justify="space-around" className="alert-wrapper-content pt-15 pb-15 pl-10">
                <Col lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={Error500} width="40" height="40" alt="Error alert icon" />
                </Col>
                <Col lg={20} md={20} sm={20} xs={20}>
                  <Row justify="center">
                    <Col lg={23} sm={23} xs={23}>
                      <p className="mb-0">{t('scheduleTime.selectBranchOffice.quantityLimitText')}</p>
                    </Col>
                    <Col lg={23} sm={23} xs={23}>
                      <p className="mb-0">{t('scheduleTime.selectBranchOffice.disclaimerMaxText')} <strong onClick={showFormModal}><a><u>haz clic aquí.</u></a></strong></p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Modal
              open={formModalVisible}
              onCancel={closeModal}
              footer={false}
              closable={false}
              centered
              width={350}
              className="modal-form-wrapper"
            >
              <Row justify="center">
                <div className="modal-border-top" />
                {
                  !isSuccess ?
                    <Col lg={23} md={22} sm={22} xs={20}>
                      <Row justify="center">
                        <Col lg={20} sm={22} xs={24} className="mt-20">
                          <h3>{t('scheduleTime.selectBranchOffice.maxReservationTitle')}</h3>
                        </Col>
                        <Col lg={20} sm={22} xs={24}>
                          <p>{t('scheduleTime.selectBranchOffice.contactText')}</p>
                        </Col>
                        <Col lg={20} sm={22} xs={24} className="mb-30">
                          <Form
                            form={form}
                            onFinish={onFinish}
                            layout="vertical"
                          >
                            <Form.Item
                              name="nameAndLastName"
                              className="mb-15"
                              rules={[{ required: true, message: 'Debes ingresar tu nombre y apellido' }]}
                            >
                              <Input
                                placeholder="Nombre y apellido"
                                size="large"
                                className='cmt-input'
                              />
                            </Form.Item>

                            <PhoneInput.ReactPhone
                              countryCode={countryCode}
                              form={form}
                            />

                            <Form.Item name="email" className="mb-20" rules={[{ required: true, message: 'Debes ingresar tu email' }]}>
                              <Input placeholder="Correo electrónico" size="large" className='cmt-input' />
                            </Form.Item>
                            <Row justify="center">
                              <Col lg={11} xs={24}>
                                <Button loading={loading.submit} htmlType="submit" size="large" block>Enviar</Button>
                              </Col>
                            </Row>
                          </Form>
                        </Col>
                      </Row>
                    </Col>
                    :
                    <Col lg={23} md={21} sm={21} xs={20}>
                      <Row justify="center">
                        <Col lg={20} xs={24} className="mt-30">
                          <h3>{t('scheduleTime.selectBranchOffice.sentMessageTitle')}</h3>
                        </Col>
                        <Col lg={20} xs={24}>
                          <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.selectBranchOffice.contactDisclaimerText') }} />
                        </Col>
                        <Col lg={9} xs={24} className="mt-20 mb-30">
                          <Button onClick={closeModal} size="large" block>Cerrar</Button>
                        </Col>
                      </Row>
                    </Col>
                }
              </Row>
            </Modal>
          </Row>
          <Row justify="end" className="button-wrapper mt-lg-0 mt-md-110 mt-sm-100 mt-xs-70 mb-40">
            <Col lg={3} md={15} sm={23} xs={23}>
              <Button
                size="large"
                className="next-button reservations-step2"
                onClick={handleChangeState}
                disabled={
                  !branchOffice.id
                  || !canReserve
                  || (costCenters.list.length > 0 && !costCenter)
                  || !documentNumber
                  || !patientsQuantity
                }
                block
              >
                Siguiente
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default SelectBranchOffice
